import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-count-header',
  templateUrl: './count-header.component.html',
  styleUrls: ['./count-header.component.scss'],
})
export class CountHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
